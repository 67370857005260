

























import Vue from "vue";
import PreFooter from "@/components/PreFooter.vue";
import Testimonial from "@/components/Testimonial.vue";
import testimonials from "@/assets/data/testimonials.json";

export default Vue.extend({
  components: {
    PreFooter,
    Testimonial,
  },
  data() {
    return {
      testimonials,
      index: 0,
      interval: 0,
    };
  },
  metaInfo(): { title: string } {
    return { title: `Download Bkmark` };
  },
  methods: {
    submit() {
      // @ts-ignore
      gtag("event", "download-bookmarklet", {
        event_category: "click",
      });
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.index === 4) {
        return (this.index = 0);
      }
      this.index += 1;
    }, 3000);
  },
});
